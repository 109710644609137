<template>
	<div class="dreams-records">
		<Header title="Records" />
		<div class="dreams-container">
			<div class="dreams-tabBox">
				<div :class="['dreams-tab', tab === 0 ? 'dreams-on' : '']" @click="changeTab(0)">
					<span>ALL</span>
				</div>
				<div :class="['dreams-tab', tab === 1 ? 'dreams-on' : '']" @click="changeTab(1)">
					<span>Submission</span>
				</div>
				<div :class="['dreams-tab', tab === 2 ? 'dreams-on' : '']" @click="changeTab(2)">
					<span>Completed</span>
				</div>
				<div :class="['dreams-tab', tab === 3 ? 'dreams-on' : '']" @click="changeTab(3)">
					<span>Pending</span>
				</div>
			</div>
			<div class="dreams-content">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh" loading-text="Loading..." loosing-text=" "
					pulling-text=" ">
					<van-list v-model="loading" :finished="finished" @load="initPage" loading-text="Loading..."
						class="dreams-list">
						<div class="dreams-box" v-for="item in dataList" :key="item.orderRecordsId">
							<div class="dreams-titleBox">
								<div class="dreams-time">{{ item.createTime | formatTime('YMDHMS') }}</div>
								<div class="dreams-buttonBox">
									<span class="dreams-Submission"
										v-if="item.status === 'submission' && hasPendingStatus">wait for completion</span>
									<span class="dreams-SubmitNow" @click="submitOrder(item)"
										v-if="item.status === 'submission'">Submit now</span>
									<span class="dreams-Completed" v-if="item.status === 'completed'">Completed</span>
									<span class="dreams-Pending" v-if="item.status === 'pending'">Pending</span>
								</div>
							</div>
							<div class="dreams-infoBox">
								<div class="dreams-top">
									<img :src="item.imgUrl" class="dreams-img" alt="" style="width: 65px; height: 65px" />
									<div class="dreams-info">
										<div class="dreams-number">
											<!-- <span>N0.:{{ item.orderNumber }}</span> -->
											<span class="dreams-Pending"
												v-if="item.status === 'submission' && hasPendingStatus">Combination task funds frozen</span>
												<span class="dreams-Pending" v-if="item.status === 'pending'">Combination task funds frozen</span>
											<!-- <img src="@/assets/images/copy.png" alt="" @click="copyed(item)"
												style="width: 13px; margin-left: 3px" /> -->
										</div>
										<div class="dreams-name">{{ item.title }}</div>
									</div>
								</div>
								<div class="dreams-bottom">
									<div class="dreams-item">
										<div class="dreams-name">Total amount</div>
										<div class="dreams-value">
											USDT {{ (parseFloat(item.price) / 100).toFixed(2) }}
										</div>
									</div>
									<div class="dreams-item">
										<div class="dreams-name">Profit</div>
										<div class="dreams-value">
											USDT {{ (parseFloat(item.income) / 100).toFixed(2) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header.vue";
	export default {
		components: {
			Header
		},
		data() {
			return {
				tab: 0,
				dataList: [],
				loading: false,
				finished: false,
				refreshing: false,
				params: {
					orderDetailEnum: '',
					pageNum: 1,
					pageSize: 10,
					total: 100,
				},
				dataList: [],
			};
		},
		computed: {
			// 判断是否存在状态为pending的订单
			hasPendingStatus() {
				return this.dataList.some(item => item.status === 'pending');
			}
		},
		methods: {
			copyed(item) {
				const orderNumber = item.orderNumber;
				var input = document.createElement("input"); // 创建input对象
				input.value = orderNumber; // 设置复制内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.toast('Copy Successfully');
			},
			submitOrder(item) {
				this.confirm(
					"Optimize the data?",
					() => {
						this.getAjax('api/en/ajjepzscn/async/subOrder', {}, 'get').then(res => {
							this.showInfo('Submitted successfully');
							this.onRefresh();
						})
					},
					null, {
						title: "Tips",
						confirmButtonText: "Sure",
						confirmButtonColor: "#26AAC3",
						cancelButtonText: "Cancel",
					}
				);
			},
			changeTab(tab) {
				this.tab = tab;
				if (tab === 0) {
					this.params.orderDetailEnum = '';
				} else if (tab === 1) {
					this.params.orderDetailEnum = 'submission';
				} else if (tab === 2) {
					this.params.orderDetailEnum = 'completed';
				} else if (tab === 3) {
					this.params.orderDetailEnum = 'pending';
				}
				this.onRefresh();
			},
			onRefresh() {
				this.params.pageNum = 1;
				this.params.pageSize = 10;
				this.params.total = 100;
				this.finished = false;
				this.dataList = [];
				this.initPage();
			},
			async initPage() {
				//获取记录列表
				if (this.dataList.length < this.params.total) {
					const res = await this.getAjax(
						"api/en/ajjepzscn/async/taskOrders", {
							...this.params
						},
						"get"
					);
					console.log(res)
					this.loading = false;
					this.refreshing = false;
					this.params.total = res.data.total;
					res.data.list.forEach((e) => {
						this.dataList.push(e);
					});
					this.params.pageNum++;
				} else {
					this.finished = true;
				}
			},
		},
		async mounted() {
			//获取记录列表
			this.dataList = [];
		},
	};
</script>

<style lang="less" scoped>
	.dreams-records {
		padding-top: 90px;

		.dreams-container {
			padding: 0 0 15px;

			.dreams-tabBox {
				padding: 0 10px 10px;
				border-bottom: 2px solid #1dc1b9;
				display: flex;
				justify-content: space-between;

				.dreams-tab {
					text-align: left;
					display: inline-block;

					span {
						display: inline-block;
						border-bottom: 3px solid #fff;
						padding: 5px;
						color: #727b8c;
						font-size: 15px;
					}
				}

				.dreams-on {
					span {
						font-weight: bold;
						color: #23b5c0;
						border-bottom: 3px solid #23b5c0;
					}
				}
			}
			.dreams-content {
				padding: 10px;

				.dreams-box {
					margin-bottom: 10px;

					.dreams-titleBox {
						display: flex;
						align-items: center;

						.dreams-time {
							flex: 1;
							font-size: 13px;
							color: #888;
						}

						.dreams-buttonBox {
							.dreams-Submission {
								width: 130px;
								height: 22px;
								line-height: 16px;
								border: 2px solid #ffba62;
								border-radius: 4px;
								color: #ffba62;
								text-align: center;
								font-size: 12px;
								display: inline-block;
								margin-left: 10px;
							}

							.dreams-SubmitNow {
								width: 90px;
								height: 22px;
								line-height: 16px;
								border: 2px solid #facc01;
								background: #facc01;
								border-radius: 4px;
								color: #333;
								text-align: center;
								font-size: 12px;
								display: inline-block;
								margin-left: 10px;
							}

							.dreams-Completed {
								width: 90px;
								height: 22px;
								line-height: 16px;
								border: 2px solid #58c069;
								border-radius: 4px;
								color: #58c069;
								text-align: center;
								font-size: 12px;
								display: inline-block;
								margin-left: 10px;
							}

							.dreams-Pending {
								width: 90px;
								height: 22px;
								line-height: 16px;
								border: 2px solid #ff4343;
								border-radius: 4px;
								color: #ff4343;
								text-align: center;
								font-size: 12px;
								display: inline-block;
								margin-left: 10px;
							}
						}
					}

					.dreams-infoBox {
						border: 1px solid #ddd;
						border-radius: 8px;
						padding: 10px;
						margin-top: 5px;
						box-shadow: 0 2px 2px #ddd;

						.dreams-top {
							border-bottom: 1px solid #c7efed;
							display: flex;
							padding-bottom: 10px;

							.dreams-img {
								width: 300px;
								height: 300px;
							}

							.dreams-info {
								flex: 1;
								margin-left: 15px;

								.dreams-number {
									display: flex;
									align-items: center;
									justify-content: flex-end;

									span {
										color: #ff4343;
										font-size: 14px;
									}
								}

								.dreams-name {
									font-size: 14px;
									font-weight: bold;
									margin-top: 5px;
								}
							}
						}

						.dreams-bottom {
							display: flex;
							padding-top: 15px;

							.dreams-item {
								flex: 1;

								.dreams-name {
									font-size: 12px;
									color: #777;
								}

								.dreams-value {
									font-weight: bold;
									margin-top: 5px;
									color: #4cc2d3;
									font-size: 13px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>